        /* You can add global styles to this file, and also import other style files */
        /**
* Template Name: EstateAgency - v4.7.0
* Template URL: https://bootstrapmade.com/real-estate-agency-bootstrap-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/
        /*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
@import "../node_modules/angular-calendar/css/angular-calendar.css";
        body {
            font-family: "Poppins", sans-serif !important;
            color: #555555 !important;
        }
        
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: #000000 ;
        }
        
        a {
            color: #000000;
            text-decoration: none;
            transition: all 0.5s ease;
        }
        
        a:hover {
            color: #91C151;
            text-decoration: none;
        }
        
        .link-two {
            color: #000000;
            transition: all 0.5s ease;
        }
        
        .link-two:hover {
            text-decoration: underline;
            color: #000000;
        }
        
        .link-one {
            color: #000000;
            transition: all 0.5s ease;
        }
        
        .link-one:hover {
            color: #000000;
            text-decoration: none;
        }
        
        .link-icon {
            color: #000000;
            font-weight: 500;
        }
        
        .link-icon span {
            font-size: 14px;
            padding-left: 4px;
            vertical-align: middle;
        }
        
        .link-a {
            text-decoration: none;
            color: #ffffff;
            text-decoration: none;
        }
        
        @media (min-width: 768px) {
            .link-a {
                font-size: 0.9rem;
            }
        }
        
        @media (min-width: 992px) {
            .link-a {
                font-size: 1rem;
            }
        }
        
        .link-a:hover {
            color: #ffffff;
            text-decoration: none;
        }
        
        .link-a span {
            font-size: 18px;
            vertical-align: middle;
            margin-left: 5px;
        }
        
        @media (min-width: 768px) {
            .link-a span {
                font-size: 1.5rem;
            }
        }
        
        @media (min-width: 992px) {
            .link-a span {
                font-size: 18px;
            }
        }
        
        .text-brand {
            color: #000000;
            font-size: 2rem;
            font-weight: 600;
        }
        
        @media (max-width: 767px) {
            .text-brand {
                font-size: 1.2rem;
            }
        }
        
        .color-a {
            text-decoration: none;
            color: #000000;
        }
        
        .color-b {
            color: #91C151;
        }
        
        .color-d {
            color: #adadad;
        }
        
        .color-text-a {
            text-decoration: none;
            color: #555555;
        }
        
        .no-margin {
            margin: 0;
        }
        /*------/ Nav Pills  /------*/
        
        .nav-pills-a {
            text-decoration: none;
        }
        
        .nav-pills-a.nav-pills .nav-link {
            color: #000000;
            position: relative;
            font-weight: 600;
        }
        
        .nav-pills-a.nav-pills .nav-link.active {
            background-color: transparent;
        }
        
        .nav-pills-a.nav-pills .nav-link.active:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 100%;
            height: 2px;
            background-color: #91C151;
            z-index: 2;
        }
        /*------/ Bg Image /------*/
        
        .bg-image {
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-size: cover;
            background-position: center center;
        }
        /*------/ List a /------*/
        
        .list-a {
            text-decoration: none;
            display: inline-block;
            line-height: 2;
            padding: 0;
            list-style: none;
        }
        
        .list-a li {
            position: relative;
            width: 50%;
            float: left;
            padding-left: 25px;
            padding-right: 5px;
        }
        
        @media (min-width: 992px) {
            .list-a li {
                width: 33.333%;
            }
        }
        
        .list-a li:before {
            content: "";
            width: 10px;
            height: 2px;
            position: absolute;
            background-color: #313131;
            top: 15px;
            left: 0;
        }
        /*------/ Pagination-a /------*/
        
        .pagination-a {
            text-decoration: none;
        }
        
        .pagination-a .pagination .page-link {
            margin: 0 0.2rem;
            border-color: transparent;
            padding: 0.5rem 0.8rem;
            color: #000000;
        }
        
        .pagination-a .pagination .page-link:hover,
        .pagination-a .pagination .page-link:active,
        .pagination-a .pagination .page-link:focus {
            background-color: #91C151;
        }
        
        .pagination-a .pagination .page-link span {
            font-size: 1.2rem;
        }
        
        .pagination-a .pagination .next .page-link {
            padding: 0.4rem 0.9rem;
        }
        
        .pagination-a .pagination .page-item:last-child .page-link {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        
        .pagination-a .pagination .page-item:first-child .page-link {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        
        .pagination-a .pagination .page-item.disabled .page-link {
            padding: 0.4rem 0.9rem;
            color: #555555;
        }
        
        .pagination-a .pagination .page-item.active .page-link {
            background-color: #91C151;
        }
        
        .a {
            text-decoration: none;
            color: #91C151;
            font-size: 1.3rem;
        }
        /*------/ Icon Box /------*/
        
        .icon-box .icon-box-icon {
            display: table-cell;
            vertical-align: top;
            font-size: 36px;
            color: #000000;
            width: 50px;
            padding-top: 8px;
        }
        
        .icon-box .icon-box-content {
            padding-top: 18px;
        }
        /*------/ Space Padding /------*/
        
        .section-t8 {
            padding-top: 2rem;
        }
        
        @media (max-width: 767px) {
            .section-t8 {
                padding-top: 2rem;
            }
        }
        
        .section-t4 {
            padding-top: 4rem;
        }
        
        @media (max-width: 767px) {
            .section-t4 {
                padding-top: 2rem;
            }
        }
        
        .section-t3 {
            padding-top: 3rem;
        }
        
        @media (max-width: 767px) {
            .section-md-t3 {
                padding-top: 3rem;
            }
        }
        
        .section-b2 {
            padding-bottom: 2rem;
        }
        
        .section-tb85 {
            padding: 8rem 0 5rem 0;
        }
        
        @media (max-width: 767px) {
            .section-tb85 {
                padding: 4rem 0 2.5rem 0;
            }
        }
        /*------/ Title /------*/
        
        .title-wrap {
            padding-bottom: 1rem;
        }
        
        .title-a {
            text-decoration: none !important;
            font-size: 1.6rem !important;
            font-weight: 600 !important;
        }
        
        .title-link {
            font-size: 1.2rem;
            font-weight: 300;
            padding-top: 1.2rem;
        }
        
        .title-link a {
            text-decoration: none;
            color: #313131;
        }
        
        .title-link span {
            font-size: 20px;
            padding-left: 4px;
            vertical-align: middle;
        }
        
        .title-box-d {
            padding-bottom: 1.8rem;
            margin-bottom: 1rem;
            position: relative;
        }
        
        .title-box-d .title-d {
            font-weight: 600;
            font-size: 2rem;
        }
        
        .title-box-d .title-d:after {
            content: "";
            position: absolute;
            width: 70px;
            height: 4px;
            background-color: #91C151;
            bottom: 20px;
            left: 0;
        }
        /*------/ Display Table & Table Cell /------*/
        
        .display-table {
            width: 100%;
            height: 100%;
            display: table;
        }
        
        .table-cell {
            display: table-cell;
            vertical-align: middle;
        }
        /*------/ Ul Resect /------*/
        
        .ul-resect ul,
        .box-comments ul,
        .list-a ul {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
        }
        /*------/ Overlay /------*/
        
        .overlay-a {
            text-decoration: none;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 27%, rgba(0, 0, 0, 0.65) 90%);
        }
        
        .overlay {
            position: absolute;
            top: 0;
            left: 0px;
            padding: 0;
            height: 100%;
            width: 100%;
        }
        
        .carousel-pagination {
            margin-top: 10px;
            text-align: center;
        }
        
        .carousel-pagination .swiper-pagination-bullet {
            width: 18px;
            height: 10px;
            background-color: #555;
            margin: 0 4px;
            border-radius: 0;
            opacity: 1;
            transition: 0.3s;
        }
        
        .carousel-pagination .swiper-pagination-bullet-active {
            background-color: #91C151;
            width: 36px;
        }
        
        .nav-arrow-a {
            text-decoration: none;
        }
        
        .nav-arrow-a .owl-arrow .owl-nav {
            font-size: 1.8rem;
            margin-top: -110px;
        }
        
        .nav-arrow-a .owl-arrow .owl-nav .owl-next {
            margin-left: 15px;
        }
        
        .nav-arrow-b .owl-arrow .owl-nav {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 1.2rem;
        }
        
        .nav-arrow-b .owl-arrow .owl-nav .owl-prev,
        .nav-arrow-b .owl-arrow .owl-nav .owl-next {
            padding: 0.7rem 1.5rem !important;
            display: inline-block;
            transition: all 0.6s ease-in-out;
            color: #ffffff;
            background-color: #91C151;
            opacity: 0.9;
        }
        
        .nav-arrow-b .owl-arrow .owl-nav .owl-prev.disabled,
        .nav-arrow-b .owl-arrow .owl-nav .owl-next.disabled {
            transition: all 0.6s ease-in-out;
            color: #ffffff;
        }
        
        .nav-arrow-b .owl-arrow .owl-nav .owl-prev:hover,
        .nav-arrow-b .owl-arrow .owl-nav .owl-next:hover {
            background-color: #26a356;
        }
        
        .nav-arrow-b .owl-arrow .owl-nav .owl-next {
            margin-left: 0px;
        }
        /*------/ Socials /------*/
        
        .socials-a {
            text-decoration: none;
        }
        
        .socials-a .list-inline-item:not(:last-child) {
            margin-right: 25px;
        }
        /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
        
        .back-to-top {
            position: fixed;
            visibility: hidden;
            opacity: 0;
            right: 15px;
            bottom: 15px;
            z-index: 996;
            background: #91C151;
            width: 40px;
            height: 40px;
            border-radius: 50px;
            transition: all 0.4s;
        }
        
        .back-to-top i {
            font-size: 28px;
            color: #fff;
            line-height: 0;
        }
        
        .back-to-top:hover {
            background: #4bd680;
            color: #fff;
        }
        
        .back-to-top.active {
            visibility: visible;
            opacity: 1;
        }
        /* Prelaoder */
        
        #preloader {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 9999;
            overflow: hidden;
            background: #fff;
        }
        
        #preloader:before {
            content: "";
            position: fixed;
            top: calc(50% - 30px);
            left: calc(50% - 30px);
            border: 6px solid #f2f2f2;
            border-top: 6px solid #91C151;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            -webkit-animation: animate-preloader 1s linear infinite;
            animation: animate-preloader 1s linear infinite;
        }
        
        @-webkit-keyframes animate-preloader {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
        
        @keyframes animate-preloader {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
        /*======================================
  //--//-->   BUTTON
  ======================================*/
        
        .btn {
            transition: 0.5s ease;
        }
        
        .btn.btn-a,
        .btn.btn-b {
            border-radius: 0;
            padding: 1rem 3rem;
            letter-spacing: 0.05rem;
        }
        
        .btn.btn-a {
            text-decoration: none;
            background-color: #000000;
            color: #ffffff;
        }
        
        .btn.btn-a:hover {
            background-color: #91C151;
            color: #000000;
        }
        
        .btn.btn-b {
            background-color: #91C151;
            color: #000000;
        }
        
        .btn.btn-b:hover {
            background-color: #000000;
            color: #ffffff;
        }
        
        .btn.btn-b-n {
            background-color: #91C151;
            color: #ffffff;
            border-radius: 0;
        }
        
        .btn.btn-b-n:hover {
            background-color: #87db18;
            color: #ffffff;
        }
        
        .navbar-toggle-box {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            line-height: 0;
            padding: 10px;
        }
        
        .navbar-toggle-box i {
            line-height: 0;
        }
        /*--------------------------------------------------------------
  # Navbar
  --------------------------------------------------------------*/
        
        .navbar-default {
            transition: all 0.5s ease-in-out;
            background-color: #ffffff;
            padding-top: 28px;
            padding-bottom: 28px;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            /* box-shadow: 1px 2px 15px rgba(100, 100, 100, 0.3);*/
            border-bottom: 10px solid #91C151;
        }
        
        .navbar-default .nav-search {
            color: #000000;
            font-size: 1.5rem;
        }
        
        .navbar-default.navbar-reduce {
            box-shadow: 1px 2px 15px rgba(100, 100, 100, 0.3);
        }
        
        .navbar-default.navbar-trans,
        .navbar-default.navbar-reduce {
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }
        
        .navbar-default.navbar-trans .nav-item,
        .navbar-default.navbar-reduce .nav-item {
            position: relative;
            padding-right: 10px;
            padding-bottom: 8px;
            margin-left: 0;
        }
        
        @media (min-width: 768px) {
            .navbar-default.navbar-trans .nav-item,
            .navbar-default.navbar-reduce .nav-item {
                margin-left: 15px;
            }
        }
        
        .navbar-default.navbar-trans .nav-link,
        .navbar-default.navbar-reduce .nav-link {
            font-size: 1.1rem;
            color: #0e3144;
            font-weight: 600;
            letter-spacing: 0.03em;
            transition: all 0.1s ease-in-out;
            position: relative;
            padding-left: 0;
            padding-right: 0;
        }
        
        .navbar-default.navbar-trans .nav-link:before,
        .navbar-default.navbar-reduce .nav-link:before {
            content: "";
            position: absolute;
            bottom: 5px;
            left: 0;
            width: 100%;
            height: 2px;
            z-index: 0;
            background-color: #91C151;
            transform: scaleX(0);
            transform-origin: right;
            transition: transform 0.2s ease-out, opacity 0.2s ease-out 0.3s;
        }
        
        .navbar-default.navbar-trans .nav-link:hover,
        .navbar-default.navbar-reduce .nav-link:hover {
            color: #000000;
        }
        
        .navbar-default.navbar-trans .nav-link:hover:before,
        .navbar-default.navbar-reduce .nav-link:hover:before {
            transform: scaleX(1);
            transform-origin: left;
        }
        
        .navbar-default.navbar-trans .show>.nav-link:before,
        .navbar-default.navbar-trans .active>.nav-link:before,
        .navbar-default.navbar-trans .nav-link.show:before,
        .navbar-default.navbar-trans .nav-link.active:before,
        .navbar-default.navbar-reduce .show>.nav-link:before,
        .navbar-default.navbar-reduce .active>.nav-link:before,
        .navbar-default.navbar-reduce .nav-link.show:before,
        .navbar-default.navbar-reduce .nav-link.active:before {
            transform: scaleX(1);
        }
        
        .navbar-default.navbar-trans .nav-link:before {
            background-color: #91C151;
        }
        
        .navbar-default.navbar-trans .nav-link:hover {
            color: #000000;
        }
        
        .navbar-default.navbar-trans .show>.nav-link,
        .navbar-default.navbar-trans .active>.nav-link,
        .navbar-default.navbar-trans .nav-link.show,
        .navbar-default.navbar-trans .nav-link.active {
            color: #000000;
        }
        
        .navbar-default.navbar-reduce {
            transition: all 0.5s ease-in-out;
            padding-top: 19px;
            padding-bottom: 19px;
        }
        
        .navbar-default.navbar-reduce .nav-link {
            color: #000000;
        }
        
        .navbar-default.navbar-reduce .nav-link:before {
            background-color: #91C151;
        }
        
        .navbar-default.navbar-reduce .nav-link:hover {
            color: #000000;
        }
        
        .navbar-default.navbar-reduce .show>.nav-link,
        .navbar-default.navbar-reduce .active>.nav-link,
        .navbar-default.navbar-reduce .nav-link.show,
        .navbar-default.navbar-reduce .nav-link.active {
            color: #000000;
        }
        
        .navbar-default.navbar-reduce .navbar-brand {
            color: #000000;
        }
        
        .navbar-default .dropdown .dropdown-menu {
            border-top: 0;
            border-left: 4px solid #91C151;
            border-right: 0;
            border-bottom: 0;
            transform: translate3d(0px, 40px, 0px);
            opacity: 0;
            filter: alpha(opacity=0);
            visibility: hidden;
            transition: all 0.5s cubic-bezier(0.3, 0.65, 0.355, 1) 0s, opacity 0.31s ease 0s, height 0s linear 0.36s;
            margin: 0;
            border-radius: 0;
            padding: 12px 0;
        }
        
        @media (min-width: 768px) {
            .navbar-default .dropdown .dropdown-menu {
                border-top: 4px solid #91C151;
                border-left: 0;
                display: block;
                position: absolute;
                box-shadow: 0 2px rgba(17, 16, 15, 0.1), 0 2px 10px rgba(20, 19, 18, 0.1);
            }
        }
        
        .navbar-default .dropdown .dropdown-menu .dropdown-item {
            padding: 12px 18px;
            transition: all 500ms ease;
            font-weight: 600;
            min-width: 220px;
        }
        
        .navbar-default .dropdown .dropdown-menu .dropdown-item:hover {
            background-color: #ffffff;
            color: #91C151;
            transition: all 500ms ease;
        }
        
        .navbar-default .dropdown .dropdown-menu .dropdown-item.active {
            background-color: #ffffff;
            color: #91C151;
        }
        
        .navbar-default .dropdown:hover .dropdown-menu {
            transform: translate3d(0px, 0px, 0px);
            visibility: visible;
            opacity: 1;
            filter: alpha(opacity=1);
        }
        /*------/ Hamburger Navbar /------*/
        
        .navbar-toggler {
            position: relative;
        }
        
        .navbar-toggler:focus,
        .navbar-toggler:active {
            outline: 0;
        }
        
        .navbar-toggler span {
            display: block;
            background-color: #000000;
            height: 3px;
            width: 25px;
            margin-top: 4px;
            margin-bottom: 4px;
            transform: rotate(0deg);
            left: 0;
            opacity: 1;
        }
        
        .navbar-toggler span:nth-child(1),
        .navbar-toggler span:nth-child(3) {
            transition: transform 0.35s ease-in-out;
        }
        
        .navbar-toggler:not(.collapsed) span:nth-child(1) {
            position: absolute;
            left: 12px;
            top: 10px;
            transform: rotate(135deg);
            opacity: 0.9;
        }
        
        .navbar-toggler:not(.collapsed) span:nth-child(2) {
            height: 12px;
            visibility: hidden;
            background-color: transparent;
        }
        
        .navbar-toggler:not(.collapsed) span:nth-child(3) {
            position: absolute;
            left: 12px;
            top: 10px;
            transform: rotate(-135deg);
            opacity: 0.9;
        }
        /*======================================
  //--//-->   BOX COLAPSE
  ======================================*/
        
        .box-collapse {
            position: fixed;
            top: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            z-index: 1040;
            background-color: #ffffff;
            transform: translateX(100%);
            transition: all 0.6s ease;
        }
        
        @media (min-width: 768px) {
            .box-collapse {
                width: 50%;
            }
        }
        
        .box-collapse .title-box-d {
            top: 30px;
            left: 60px;
            opacity: 0;
            transition: all 1s ease;
            transition-delay: 0.3s;
        }
        
        @media (max-width: 575px) {
            .box-collapse .title-box-d {
                left: 35px;
            }
        }
        
        @media (max-width: 575px) {
            .box-collapse .title-box-d .title-d {
                font-size: 1.3rem;
            }
        }
        
        .box-collapse-wrap {
            opacity: 0;
            position: absolute;
            left: 0;
            top: 6.5rem;
            bottom: 5rem;
            padding-left: 10%;
            padding-right: 10%;
            overflow-x: hidden;
            overflow-y: auto;
            margin: 0;
            transform: translateY(3rem);
            transition: transform 0.5s 0.5s ease, opacity 0.5s 0.5s ease;
        }
        
        .box-collapse-open .click-closed {
            visibility: visible;
        }
        
        .box-collapse-open .box-collapse {
            transform: translateX(0);
            box-shadow: 0 0 65px rgba(0, 0, 0, 0.07);
            opacity: 1;
        }
        
        .box-collapse-open .box-collapse .title-box-d {
            transform: translate(0);
            opacity: 1;
        }
        
        .box-collapse-open .box-collapse-wrap {
            transform: translate(0);
            opacity: 1;
        }
        
        .box-collapse-closed .box-collapse {
            opacity: 0.7;
            transition-delay: 0s;
        }
        
        .box-collapse-closed .box-collapse .title-box-d {
            opacity: 0;
            transition-delay: 0s;
        }
        
        .box-collapse-closed .box-collapse .form-a {
            opacity: 0;
            transition-delay: 0s;
        }
        
        .click-closed {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            visibility: hidden;
            z-index: 1035;
            background-color: #000000;
            opacity: 0.4;
        }
        
        .close-box-collapse {
            position: absolute;
            z-index: 1050;
            top: 2rem;
            font-size: 3.5rem;
            line-height: 1;
            color: #000000;
            cursor: pointer;
            transition: all 0.3s ease;
        }
        
        .right-boxed {
            right: 4.2857rem;
        }
        /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
        
        .intro {
            height: 100vh;
            position: relative;
            color: #ffffff;
        }
        
        .intro .swiper-pagination {
            bottom: 50px;
        }
        
        .intro .swiper-pagination .swiper-pagination-bullet {
            width: 18px;
            height: 4px;
            background-color: #ffffff;
            border-radius: 0;
            opacity: 1;
            transition: 0.3s;
        }
        
        .intro .swiper-pagination .swiper-pagination-bullet-active {
            background-color: #91C151;
            width: 36px;
        }
        
        .intro .intro-item {
            padding-top: 2rem;
        }
        
        .intro .carousel-item-a {
            position: relative;
            height: 100vh;
        }
        
        .intro .carousel-item-a.bg-image {
            background-attachment: scroll;
        }
        
        .intro .intro-content {
            position: absolute;
        }
        
        .intro .intro-body {
            padding-left: 1rem;
            bottom: 0;
        }
        
        .lft-btm {
            bottom: 0;
            left: 0;
            position: fixed;
            padding-bottom: 1rem;
        }
        
        @media (min-width: 768px) {
            .intro .intro-body {
                padding-left: 2rem;
            }
        }
        
        .intro .intro-body .price-a {
            color: #ffffff;
            padding: 1rem 1.5rem;
            border: 2px solid #91C151;
            border-radius: 50px;
            text-transform: uppercase;
            letter-spacing: 0.03em;
            font-size: 1rem;
        }
        
        .intro .spacial {
            -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
            color: #ffffff;
        }
        
        .intro .intro-title-top {
            margin-bottom: 1rem;
            font-size: 0.9rem;
            color: #ffffff;
            letter-spacing: 0.05em;
        }
        
        @media (min-width: 768px) {
            .intro .intro-title-top {
                font-size: 0.9rem;
                margin-bottom: 1rem;
            }
        }
        
        @media (min-width: 992px) {
            .intro .intro-title-top {
                font-size: 1rem;
                margin-bottom: 2rem;
            }
        }
        
        .intro .intro-title {
            color: #ffffff;
            font-weight: 700;
            font-size: 2.8rem;
            text-transform: uppercase;
        }
        
        @media (min-width: 576px) {
            .intro .intro-title {
                font-size: 3.5rem;
            }
        }
        
        @media (min-width: 768px) {
            .intro .intro-title {
                font-size: 3.5rem;
            }
        }
        
        @media (min-width: 992px) {
            .intro .intro-title {
                font-size: 2.5rem;
            }
        }
        @media (min-width: 375px) {
            .intro .intro-title {
                font-size: 1.8rem;
            }
        }
        
        .intro .intro-subtitle {
            font-size: 1.5rem;
            font-weight: 300;
        }
        
        @media (min-width: 768px) {
            .intro .intro-subtitle {
                font-size: 2.5rem;
            }
        }
        
        .intro .intro-subtitle.intro-price {
            padding-top: 1rem;
            font-size: 1.2rem;
        }
        
        @media (min-width: 576px) {
            .intro .intro-subtitle.intro-price {
                font-size: 1.3rem;
            }
        }
        
        @media (min-width: 768px) {
            .intro .intro-subtitle.intro-price {
                padding-top: 2rem;
                font-size: 1.5rem;
            }
        }
        
        @media (min-width: 992px) {
            .intro .intro-subtitle.intro-price {
                padding-top: 1rem;
            }
        }
        /*------/ Intro Single /------*/
        
        .intro-single {
            padding: 12rem 0 3rem;
        }
        
        @media (max-width: 767px) {
            .intro-single {
                padding-top: 9rem;
            }
        }
        
        .intro-single .title-single-box {
            padding: 1rem 0 1rem 2rem;
        }
        
        .intro-single .title-single-box {
            border-left: 3px solid #91C151;
        }
        
        .intro-single .title-single-box .title-single {
            font-weight: 600;
            font-size: 2.2rem;
        }
        
        @media (min-width: 768px) {
            .intro-single .title-single-box .title-single {
                font-size: 2.1rem;
            }
        }
        
        @media (min-width: 992px) {
            .intro-single .title-single-box .title-single {
                font-size: 2.5rem;
            }
        }
        
        .intro-single .breadcrumb-box {
            padding: 1rem 0 0 0.5rem;
        }
        
        .intro-single .breadcrumb {
            background-color: transparent;
            padding-right: 0;
            padding-left: 0;
        }
        /*--------------------------------------------------------------
  # Card General
  --------------------------------------------------------------*/
        
        @media (max-width: 767px) {
            .card-box-a,
            .card-box-b,
            .card-box-c,
            .card-box-d {
                margin-bottom: 2.5rem;
            }
        }
        
        .card-box-a span,
        .card-box-b span,
        .card-box-c span,
        .card-box-d span {
            line-height: 0;
        }
        
        @media (min-width: 768px) {
            .grid .card-box-a,
            .grid .card-box-b,
            .grid .card-box-c,
            .grid .card-box-d {
                margin-bottom: 2.5rem;
            }
        }
        
        .card-box-a,
        .card-box-b,
        .card-box-d {
            position: relative;
            overflow: hidden;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }
        
        .card-box-a .img-a,
        .card-box-a .img-b,
        .card-box-b .img-a,
        .card-box-b .img-b {
            transition: 0.8s all ease-in-out;
        }
        
        @media (min-width: 768px) {
            .card-box-a:hover .img-a,
            .card-box-a:hover .img-b,
            .card-box-b:hover .img-a,
            .card-box-b:hover .img-b {
                transform: scale(1.2);
            }
        }
        
        @media (min-width: 768px) {
            .card-box-a .price-a,
            .card-box-b .price-a {
                font-size: 0.9rem;
            }
        }
        
        @media (min-width: 992px) {
            .card-box-a .price-a,
            .card-box-b .price-a {
                font-size: 1rem;
            }
        }
        
        .card-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
        
        .card-shadow {
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }
        
        .card-shadow:before {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 27%, rgba(0, 0, 0, 0.65) 90%);
        }
        /*======================================
  //--//-->   PROPERTY - CARD-A
  ======================================*/
        
        @media (min-width: 768px) {
            .card-box-a:hover .card-overlay-a-content {
                bottom: 35px;
            }
        }
        
        @media (min-width: 768px) and (min-width: 768px) {
            .card-box-a:hover .card-overlay-a-content {
                bottom: 35px;
            }
        }
        
        @media (min-width: 768px) and (min-width: 992px) {
            .card-box-a:hover .card-overlay-a-content {
                bottom: 35px;
            }
        }
        
        @media (min-width: 768px) {
            .card-box-a:hover .card-body-a {
                padding-bottom: 1rem;
            }
        }
        
        @media (max-width: 767px) {
            .card-box-a .card-overlay-a-content {
                bottom: 35px;
            }
            .card-box-a .card-body-a {
                padding-bottom: 1rem;
            }
        }
        
        .card-overlay-a-content {
            transition: all 0.5s ease-in;
            position: absolute;
            width: 100%;
            bottom: 0px;
            z-index: 2;
        }
        
        @media (min-width: 768px) {
            .card-overlay-a-content {
                bottom: -20px;
            }
        }
        
        @media (min-width: 992px) {
            .card-overlay-a-content {
                bottom: 0px;
            }
        }
        
        .card-header-a {
            padding: 0 1rem;
        }
        
        .card-header-a .card-title-a {
            color: #ffffff;
            margin-bottom: 0;
            padding-bottom: 0.7rem;
        }
        
        @media (min-width: 768px) {
            .card-header-a .card-title-a {
                font-size: 1.3rem;
            }
        }
        
        @media (min-width: 992px) {
            .card-header-a .card-title-a {
                font-size: 2rem;
            }
        }
        
        .card-header-a .card-title-a a {
            color: #ffffff;
            text-decoration: none;
        }
        
        .card-body-a {
            z-index: 2;
            transition: all 0.5s ease-in;
            padding: 0rem 1rem 2rem 1rem;
        }
        
        .card-body-a .price-box {
            padding-bottom: 0.5rem;
        }
        
        .price-a {
            color: #ffffff;
            padding: 0.6rem 0.8rem;
            border: 2px solid #91C151;
            border-radius: 50px;
            text-transform: uppercase;
            letter-spacing: 0.03em;
        }
        
        .card-footer-a {
            width: 100%;
            position: absolute;
            z-index: 2;
            color: white;
            background-color: #0e3144;
 
        }
        
        .card-info {
            list-style: none;
            margin-bottom: 0;
            padding: 0.5rem 0;
        }
        
        .card-info .card-info-title {
            font-size: 1rem;
            /*color: #313131;*/
            color: white;
        }
        
        @media (min-width: 768px) {
            .card-info .card-info-title {
                font-size: 0.9rem;
            }
        }
        
        @media (min-width: 992px) {
            .card-info .card-info-title {
                font-size: 1rem;
            }
        }
        
        .card-info li span {
            color: #ffffff;
            font-size: 0.9rem;
        }
        
        @media (min-width: 768px) {
            .card-info li span {
                font-size: 0.8rem;
            }
        }
        
        @media (min-width: 992px) {
            .card-info li span {
                font-size: 1rem;
            }
        }
        /*------/ Property Grid /-----*/
        
        .property-grid .grid-option {
            text-align: right;
            margin-bottom: 2rem;
        }
        
        .property-grid .grid-option .custom-select {
            border-radius: 0;
            width: 200px;
            border-color: #313131;
        }
        
        .property-grid .grid-option .custom-select:hover,
        .property-grid .grid-option .custom-select:active,
        .property-grid .grid-option .custom-select:focus {
            border-color: #313131;
        }
        
        .property-grid .grid-option .custom-select option {
            border-color: #313131;
        }
        /*------/ Property Single /-----*/
        
        .gallery-property {
            margin-bottom: 3rem;
        }
        
        .property-price {
            margin: 0 auto;
        }
        
        .property-price .card-header-c {
            padding: 0;
        }
        
        .summary-list {
            padding-right: 1rem;
            color: #000000;
        }
        
        .summary-list .list {
            padding: 0;
            line-height: 2;
        }
        
        .summary-list .list span {
            color: #555555;
        }
        
        @media (max-width: 767px) {
            .property-agent {
                margin-top: 2.5rem;
            }
        }
        
        .property-agent .title-agent {
            font-weight: 600;
        }
        
        .property-agent ul {
            line-height: 2;
            color: #000000;
        }
        
        .property-agent .socials-a {
            text-align: center;
        }
        
        @media (max-width: 991px) {
            .property-contact {
                margin-top: 2.5rem;
            }
        }
        /*======================================
  //--//-->   NEWS - CARD-B
  ======================================*/
        
        .card-header-b {
            padding: 1rem;
            color: #ffffff;
            position: absolute;
            bottom: 20px;
            z-index: 2;
        }
        
        @media (min-width: 768px) {
            .card-header-b {
                bottom: 0px;
            }
        }
        
        @media (min-width: 992px) {
            .card-header-b {
                bottom: 20px;
            }
        }
        
        .card-header-b .category-b {
            font-size: 0.9rem;
            background-color: #91C151;
            padding: 0.3rem 0.7rem;
            color: #313131;
            letter-spacing: 0.03em;
            border-radius: 50px;
            text-decoration: none;
        }
        
        .card-header-b .title-2 {
            margin-bottom: 0;
            padding: 0.6rem 0;
            font-size: 1.5rem;
        }
        
        @media (min-width: 768px) {
            .card-header-b .title-2 {
                font-size: 1rem;
            }
        }
        
        @media (min-width: 992px) {
            .card-header-b .title-2 {
                font-size: 1.6rem;
            }
        }
        
        .card-header-b .title-2 a {
            color: #ffffff;
            text-decoration: none;
        }
        
        .card-header-b .date-b {
            color: #d8d8d8;
            font-size: 1rem;
        }
        
        @media (min-width: 768px) {
            .card-header-b .date-b {
                font-size: 0.9rem;
            }
        }
        
        @media (min-width: 992px) {
            .card-header-b .date-b {
                font-size: 1rem;
            }
        }
        /*------/ News Single /------*/
        
        .post-information {
            padding: 3rem 0;
        }
        
        .post-content {
            font-size: 1.1rem;
            font-weight: 300;
            line-height: 1.6;
        }
        
        .post-content .post-intro {
            font-size: 1.7rem;
            font-weight: 300;
            margin-bottom: 4rem;
            line-height: 1.6;
            color: #000000;
            font-weight: 400;
        }
        
        .post-content p {
            margin-bottom: 1.7rem;
        }
        
        .post-content .blockquote {
            padding: 1rem 3rem 1.7rem 3rem;
            border-left: 3px solid #91C151;
            color: #000000;
        }
        
        .post-content .blockquote footer {
            text-align: left;
            padding: 0;
        }
        
        .post-footer {
            padding: 3rem 0 4rem;
        }
        
        .post-share .socials {
            display: inline-block;
        }
        
        .post-share .socials li {
            margin-left: 0.6rem;
        }
        /*------/ Comments /------*/
        
        .box-comments .list-comments li {
            padding-bottom: 3.5rem;
        }
        
        .box-comments .list-comments .comment-avatar {
            display: table-cell;
            vertical-align: top;
        }
        
        .box-comments .list-comments .comment-avatar img {
            width: 80px;
            height: 80px;
        }
        
        .box-comments .list-comments .comment-author {
            font-size: 1.3rem;
        }
        
        @media (min-width: 768px) {
            .box-comments .list-comments .comment-author {
                font-size: 1.5rem;
            }
        }
        
        .box-comments .list-comments .comment-details {
            display: table-cell;
            vertical-align: top;
            padding-left: 25px;
        }
        
        .box-comments .list-comments .comment-description {
            padding: 0.8rem 0 0.5rem 0;
        }
        
        .box-comments .list-comments a {
            color: #000000;
        }
        
        .box-comments .list-comments span {
            display: block;
            color: #2B2A2A;
            font-style: italic;
        }
        
        .box-comments .comment-children {
            margin-left: 40px;
        }
        /*======================================
  //--//-->   SERVICES - CARD-C
  ======================================*/
        
        .card-box-c {
            position: relative;
        }
        
        @media (max-width: 767px) {
            .card-box-c {
                margin-bottom: 2rem;
            }
        }
        
        .card-header-c {
            padding: 0 1rem 1rem 1rem;
        }
        
        .card-body-c {
            padding: 0.5rem 0.5rem 0 0.5rem;
        }
        
        @media (max-width: 767px) {
            .card-body-c {
                padding: 0;
            }
        }
        
        .card-footer-c {
            padding-left: 0.5rem;
        }
        
        .card-box-ico {
            padding: 1rem 1rem 1rem 1rem;
            border: 5px solid #91C151;
        }
        
        .card-box-ico span {
            font-size: 4rem;
            color: #000000;
        }
        
        .title-c {
            font-size: 1.8rem !important;
            font-weight: 600 !important;
            margin-left: -40px !important;
        }
        
        @media (min-width: 768px) {
            .title-c {
                font-size: 1.8rem !important;
            }
        }
        
        @media (min-width: 992px) {
            .title-c {
                font-size: 1.8rem !important;
            }
        }
        @media (max-width: 425px) {
            .title-c {
                font-size: 1.3rem !important;
            }
        }
        /*======================================
  //--//-->   AGENTS - CARD-D
  ======================================*/
        
        .card-box-d .card-overlay-hover {
            transition: all 0.2s ease-in-out;
            padding: 15px 40px 15px 35px;
            cursor: pointer;
        }
        
        @media (min-width: 768px) {
            .card-box-d .card-overlay-hover {
                padding: 5px 15px 5px 10px;
            }
        }
        
        @media (min-width: 992px) {
            .card-box-d .card-overlay-hover {
                padding: 5px 40px 5px 35px;
            }
        }
        
        @media (min-width: 1200px) {
            .card-box-d .card-overlay-hover {
                padding: 15px 40px 5px 35px;
            }
        }
        
        .card-box-d .title-d {
            transition: 0.3s ease-in-out;
            font-size: 2rem;
            font-weight: 600;
            margin: 1rem 0;
            transform: translateY(-20px);
            opacity: 0;
        }
        
        @media (min-width: 768px) {
            .card-box-d .title-d {
                font-size: 1.1rem;
                margin: 0.5rem 0;
            }
        }
        
        @media (min-width: 992px) {
            .card-box-d .title-d {
                font-size: 1.5rem;
                margin: 1rem 0;
            }
        }
        
        @media (min-width: 1200px) {
            .card-box-d .title-d {
                font-size: 2rem;
                margin: 1rem 0;
            }
        }
        
        .card-box-d .content-d {
            opacity: 0;
            transition: 0.5s ease-in-out;
            transform: translateY(-40px);
        }
        
        @media (min-width: 768px) {
            .card-box-d .content-d {
                font-size: 0.8rem;
                margin: 0.5rem 0;
            }
        }
        
        @media (min-width: 768px) {
            .card-box-d .card-body-d p {
                margin-bottom: 8px;
                font-size: 0.8rem;
            }
        }
        
        @media (min-width: 992px) {
            .card-box-d .card-body-d p {
                margin-bottom: 12px;
                font-size: 1rem;
            }
        }
        
        .card-box-d .info-agents {
            opacity: 0;
            transition: 0.5s ease-in-out;
        }
        
        .card-box-d .card-footer-d {
            transition: 0.5s ease-in-out;
            transform: translateY(40px);
            opacity: 0;
            position: absolute;
            width: 100%;
            bottom: 10px;
            left: 0;
        }
        
        .card-box-d .list-inline-item:not(:last-child) {
            margin-right: 25px;
        }
        
        .card-box-d:hover .card-overlay-hover {
            background-color: #91C151;
            opacity: 0.9;
        }
        
        .card-box-d:hover .title-d,
        .card-box-d:hover .content-d,
        .card-box-d:hover .info-agents,
        .card-box-d:hover .card-footer-d {
            opacity: 1;
            transform: translateY(0);
        }
        /*------/ Agent Single /------*/
        
        .agent-info-box .socials-footer {
            margin-top: 2rem;
        }
        
        .agent-info-box .socials-footer li {
            margin-right: 1.5rem;
        }
        /*--------------------------------------------------------------
  # About Section
  --------------------------------------------------------------*/
        
        .section-about .title-vertical {
            position: absolute;
            letter-spacing: 0;
            transform: rotate(-90deg);
            top: 50px;
            text-align: left;
            font-size: 1.8rem;
            width: 300px;
            font-weight: 700;
            text-align: right;
        }
        
        .about-img-box {
            padding-left: 1rem;
            position: relative;
        }
        
        @media (min-width: 576px) {
            .about-img-box {
                padding-left: 4rem;
            }
        }
        
        @media (min-width: 768px) {
            .about-img-box {
                padding-left: 5rem;
            }
        }
        
        .sinse-box {
            background-color: #91C151;
            padding: 1rem 2rem;
            position: absolute;
            left: 15px;
            bottom: 10px;
        }
        
        @media (min-width: 576px) {
            .sinse-box {
                padding: 1.5rem 2.5rem;
                bottom: 20px;
            }
        }
        
        @media (min-width: 768px) {
            .sinse-box {
                padding: 1.5rem 3rem;
            }
        }
        
        @media (min-width: 992px) {
            .sinse-box {
                padding: 3rem 4.5rem;
            }
        }
        
        .sinse-box .sinse-title {
            font-weight: 700;
            letter-spacing: 0.043em;
            font-size: 1rem;
        }
        
        @media (min-width: 576px) {
            .sinse-box .sinse-title {
                font-size: 1.3rem;
            }
        }
        
        @media (min-width: 768px) {
            .sinse-box .sinse-title {
                font-size: 1.8rem;
            }
        }
        
        @media (min-width: 992px) {
            .sinse-box .sinse-title {
                font-size: 2.2rem;
            }
        }
        
        .sinse-box .sinse-title span {
            position: absolute;
        }
        
        .sinse-box .sinse-title span:after {
            content: " ";
            width: 35px;
            height: 2px;
            background-color: #000000;
            position: absolute;
            bottom: -15px;
        }
        
        .sinse-box p {
            margin-bottom: 0;
            font-size: 0.7rem;
        }
        
        @media (min-width: 576px) {
            .sinse-box p {
                font-size: 0.8rem;
            }
        }
        
        @media (min-width: 576px) {
            .sinse-box p {
                font-size: 1rem;
            }
        }
        /*--------------------------------------------------------------
  # Testimonials Section
  --------------------------------------------------------------*/
        
        .testimonials-box {
            padding: 1rem 0;
        }
        
        .testimonials-box .testimonial-avatar {
            width: 80px !important;
            display: inline-flex !important;
            border-radius: 50%;
        }
        
        .testimonial-ico {
            text-align: center;
        }
        
        .testimonial-ico i {
            font-size: 48px;
            color: #91C151;
        }
        
        .testimonial-text {
            font-style: italic;
            margin-top: 25px;
            padding: 1.5rem 1.5rem;
            background-color: #f3f3f3;
            position: relative;
        }
        
        .testimonial-text:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 25px;
            width: 0px;
            height: 0px;
            border-top: 15px solid #f3f3f3;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
        }
        
        .testimonial-author-box {
            margin-top: 2rem;
        }
        
        .testimonial-author {
            margin-left: 1rem;
            display: inline-flex;
            font-size: 1.2rem;
            color: #000000;
        }
        /*--------------------------------------------------------------
  # Contact Section
  --------------------------------------------------------------*/
        
        .form-as #sendmessage {
            color: #91C151;
            border: 1px solid #26a356;
            display: none;
            text-align: center;
            padding: 15px;
            font-weight: 600;
            margin-bottom: 15px;
        }
        
        .form-as #errormessage {
            color: red;
            display: none;
            border: 1px solid red;
            text-align: center;
            padding: 15px;
            font-weight: 600;
            margin-bottom: 15px;
        }
        
        .form-as #sendmessage.show,
        .form-as #errormessage.show,
        .form-as .show {
            display: block;
        }
        
        .form-as .validation {
            color: red;
            display: none;
            margin: 4px 0 20px 0;
            font-weight: 400;
            font-size: 13px;
        }
        
        .form-as label {
            color: #000000;
            position: relative;
        }
        
        .form-as select.form-control-lg:not([size]):not([multiple]) {
            height: 3.5rem;
        }
        
        .form-as .form-control {
            border-radius: 0;
            font-size: 1.1rem;
            font-weight: 300;
        }
        
        .form-as .form-control.form-control-a {
            height: 3.5rem;
        }
        
        .form-as .form-control:focus {
            box-shadow: none;
            border-color: #91C151;
        }
        
        .php-email-form .error-message {
            display: none;
            color: #fff;
            background: #ed3c0d;
            text-align: center;
            padding: 15px;
            font-weight: 600;
        }
        
        .php-email-form .sent-message {
            display: none;
            color: #fff;
            background: #18d26e;
            text-align: center;
            padding: 15px;
            font-weight: 600;
        }
        
        .php-email-form .loading {
            display: none;
            background: #fff;
            text-align: center;
            padding: 15px;
        }
        
        .php-email-form .loading:before {
            content: "";
            display: inline-block;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            margin: 0 10px -6px 0;
            border: 3px solid #18d26e;
            border-top-color: #eee;
            -webkit-animation: animate-loading 1s linear infinite;
            animation: animate-loading 1s linear infinite;
        }
        
        .php-email-form label {
            color: #000000;
            position: relative;
        }
        
        .php-email-form select.form-control-lg:not([size]):not([multiple]) {
            height: 3.5rem;
        }
        
        .php-email-form .form-control {
            border-radius: 0;
            font-size: 1.1rem;
            font-weight: 300;
        }
        
        .php-email-form .form-control.form-control-a {
            height: 3.5rem;
        }
        
        .php-email-form .form-control:focus {
            box-shadow: none;
            border-color: #91C151;
        }
        
        @-webkit-keyframes animate-loading {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
        
        @keyframes animate-loading {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
        /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
        
        .section-footer {
            padding-top: 60px;
            margin-top: 60px;
            background: #f3f3f3;
        }
        
        .section-footer ul {
            line-height: 2;
        }
        
        .section-footer .item-list-a i {
            font-size: 18px;
            padding-right: 4px;
            color: #91C151;
        }
        
        .section-footer .widget-a .w-title-a {
            margin-bottom: 1rem;
        }
        
        footer {
            background: #f3f3f3;
            text-align: center;
            padding: 30px 0;
        }
        
        footer .copyright-footer {
            border-top: 1px solid #ddd;
            padding-top: 20px;
        }
        
        footer .credits {
            font-size: 14px;
        }
        /*Estilos vabait*/
        /*#91C151 verde
  #0e3144 azul*/
        
        .logo-vabait {
            max-width: 153px;
            height: 58.5px;
            -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
            -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
            -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
            -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
            transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        }
        /*Modal vista 360 pagina detalles*/
        
         :root {
            --clip: circle(0 at top right);
            --transitionModal: .5s transform;
            --show: scale(0);
            --transitionBackground: .5s clip-path;
        }
        /** {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}*/
        
        .modal {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.8);
            display: flex;
            clip-path: circle(0 at top right);
            transition: var(--transitionBackground)
        }
        
        .modal-show {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.8);
            display: flex;
            clip-path: circle(150% at top right);
            transition: var(--transitionBackground);
            z-index: 9999;
        }
        
        .modal:target {
            --clip: circle(150% at top right);
        }
        
        .modal_container {
            background-color: white;
            width: 100%;
            max-width: 1200px;
            height: 100%;
            margin: auto;
            /*display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(4, 1fr);
    */
            position: relative;
            transform: var(--show);
            transform: var(--transitionModal);
        }
        
        .modal_close {
            position: absolute;
            background-color: crimson;
            color: white;
            width: 50px;
            height: 50px;
            display: block;
            cursor: pointer;
            text-align: center;
            line-height: 50px;
            text-decoration: none;
            top: 0;
            right: 0;
            font-family: sans-serif;
            font-weight: bold;
            z-index: 9999;
            font-size: large;
        }
        
        .modal_close:hover {
            color: white;
        }
        
        .modal_title {
            font-size: 3rem;
        }
        
        .modal_paragraph {
            font-size: 1.3rem;
        }
        /*
        Angular Material
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

*/
/*Estilos carga de archivos drag and drop*/

.main-container {
    margin-top: 40px;
}


/* Drop Zone */

.drop-zone {
    border: dotted 3px lightgray;
    opacity: 0.5;
    color: #666;
    text-align: center
}

.file-over {
    border: dotted 3px dodgerblue;
}

.table {
    margin-top: 30px;
}

.text-green {
    color: green
}


/*botones*/


/*#91C151 verde
#0e3144 azul*/
.btn-modulo {
    background-color: #0e3144;
    color: white;
    text-decoration: none;
    padding: .8rem;
   /* border-radius: 5%;*/
    display: inline-block;
    font-size: 1rem;
}

.btn-modulo:hover {
    background-color: #91C151;
    color: white;
}

.btn-enviar {
    background-color: #91C151 !important;
    color: white !important;
}

.btn-enviar:hover {
    background-color: #91C151 !important;
    color: white !important;
}

.btn-cancelar {
    background-color: #0e3144 !important;
    color: white !important;
}

.btn-cancelar:hover {
    background-color: #0e3144 !important;
    color: white !important;
}

.verde {
    background-color: #91C151;
    color: white;
}


/*Estilos inputs file*/

.file-select {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.file-select::before {
    background-color: #91C151;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    content: 'Seleccionar';
    cursor: pointer;
    /* texto por defecto */
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.file-select input[type="file"] {
    opacity: 0;
    width: 200px;
    height: 32px;
    cursor: pointer;
    display: inline-block;
}

#src-file1::before {
    content: 'Seleccionar Archivo';
    cursor: pointer;
}

#src-file2::before {
    content: 'Seleccionar Archivo 2';
}
.descargar{
    color: #0e3144;
    text-decoration: none;
    font-weight: bold;
}


/*custom modal*/

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    clip-path: circle(0 at top right);
    transition: var(--transitionBackground)
}

.modal-show {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    clip-path: circle(150% at top right);
    transition: var(--transitionBackground);
    z-index: 9999;
}

.modal:target {
    --clip: circle(150% at top right);
}

.modal_container {
    background-color: white;
    width: 95%;
    max-width: 1200px;
    min-height: 95%;
    margin: auto;
    /*display: grid;
grid-template-columns: repeat(8, 1fr);
grid-template-rows: repeat(4, 1fr);
*/
    position: relative;
    transform: var(--show);
    transform: var(--transitionModal);
}

.modal_close {
    position: absolute;
    background-color: crimson;
    color: white;
    width: 50px;
    height: 50px;
    display: block;
    cursor: pointer;
    text-align: center;
    line-height: 50px;
    text-decoration: none;
    top: 0;
    right: 0;
    font-family: sans-serif;
    font-weight: bold;
    z-index: 9999;
    font-size: large;
}

.modal_close:hover {
    color: white;
}

.modal_title {
    font-size: 3rem;
}

.modal_paragraph {
    font-size: 1.3rem;
}

/*multiselect dropdown*/

.multiselect-dropdown .dropdown-btn {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 2rem 0.625rem 1rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #424551;
    vertical-align: middle;
    
    border: 1px solid #dadbdd;
    border-radius: 0.25rem;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}


agm-map {
    width: 100%;
    min-height: auto;
    height: 400px;
    background-color: grey;
}

/*Estilos pdf extended viewer*/
button#print,
button#print * {
  display: block !important;
}

button#download,
button#download * {
  display: block !important;
}
/*
#toolbarViewerMiddle {
  display: none;
}
div.page{
    margin: 0 !important;
    overflow: hidden !important;
}
*/

ngx-extended-pdf-viewer #mainContainer {
    min-width: 290px !important;
}

/* Sidebar scroll personalizado */

.mat-drawer-inner-container::-webkit-scrollbar {
    display: none;
}